import React, {useState, useEffect} from "react"
import {Formik, Form, Field, ErrorMessage} from "formik"
import Swal from "sweetalert2"
import Layout from "../../../components/layout"
import Navbar from "../../../components/navbar"
import Seo from "../../../components/seo"
import "./styles.css"
import {navigate} from "gatsby";

import * as Services from "../../../services"

function AddEvaluationPage(props) {
    // console.log(props);
    const [auth, setAuth] = useState('')
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [productores, setProductores] = useState([]);
    const [evaluacionNpsID, setEvaluacionNpsID] = useState(0);
    const [productorID, setProductorID] = useState(0);
    const [report, setReport] = useState(null);
    const [cultivo, setCultivo] = useState('');

    useEffect(() => {
        console.log(window.history.state);
        Services.Productores().then((response) => {
            setProductores(response.productores);
            if (window.history.state !== undefined) {
                const {productor, reportes} = window.history.state;
                console.log(reportes);
                setProductorID(productor.id);
                if (reportes.length >= 1) {
                    setReport(reportes[0]);
                    setCultivo(reportes[0].cultivo);
                    setEvaluacionNpsID(reportes[0].evaluacionNpsID);
                    setStep1(false);
                    setStep3(true);
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }, [])


    useEffect(() => {
        setAuth(localStorage.getItem("auth"))
    }, [])

    let authUser = null
    let user = null

    if (auth) {
        authUser = JSON.parse(auth)
        const {user: authUsr} = authUser
        user = authUsr
    }

    const initialValues = {
        productorID: productorID.toString(),
        producto: "",
        dosisHA: "0",
        metodoAplicacion: "",
        frecuencia: 0,
        duracion: 0,
        cultivo: "",
        variedad: "",
        objetivo: "",
    }

    const initialValues2 = {
        dia: '',
        tipo: 'testigo',
        datosRegistrados: [
            {hojas: 0, coronas: 0, frutos: 0},
            {hojas: 0, coronas: 0, frutos: 0},
            {hojas: 0, coronas: 0, frutos: 0},
            {hojas: 0, coronas: 0, frutos: 0},
            {hojas: 0, coronas: 0, frutos: 0},
        ],
        tipo2: 'tratamiento',
        datosRegistrados2: [
            {hojas: 0, coronas: 0, frutos: 0},
            {hojas: 0, coronas: 0, frutos: 0},
            {hojas: 0, coronas: 0, frutos: 0},
            {hojas: 0, coronas: 0, frutos: 0},
            {hojas: 0, coronas: 0, frutos: 0},
        ],
    }

    const initialValues3 = {
        dia: '',
        tipo: 'testigo',
        datosRegistrados: [
            {bBasales: 0, bCiegos: 0, bAbiertos: 0, frutos: 0},
            {bBasales: 0, bCiegos: 0, bAbiertos: 0, frutos: 0},
            {bBasales: 0, bCiegos: 0, bAbiertos: 0, frutos: 0},
            {bBasales: 0, bCiegos: 0, bAbiertos: 0, frutos: 0},
            {bBasales: 0, bCiegos: 0, bAbiertos: 0, frutos: 0},
        ],
        tipo2: 'tratamiento',
        datosRegistrados2: [
            {bBasales: 0, bCiegos: 0, bAbiertos: 0, frutos: 0},
            {bBasales: 0, bCiegos: 0, bAbiertos: 0, frutos: 0},
            {bBasales: 0, bCiegos: 0, bAbiertos: 0, frutos: 0},
            {bBasales: 0, bCiegos: 0, bAbiertos: 0, frutos: 0},
            {bBasales: 0, bCiegos: 0, bAbiertos: 0, frutos: 0},
        ],
    }

    const sendEvaluacion = async (values, {setSubmitting}) => {
        if (auth) {
            authUser = JSON.parse(auth);
            const {user: authUsr} = authUser;
            user = authUsr;
        }
        setTimeout(() => {
            const body = {
                ...values,
                productorID: parseInt(values.productorID, 10),
                userID: user.id,
                frecuencia: parseInt(values.frecuencia, 10),
                duracion: parseInt(values.duracion, 10),
            }

            Services.AddEvaluation(body).then((response) => {
                setCultivo(values.cultivo);
                console.log(response)
                setEvaluacionNpsID(response.evaluacion?.id)
                Swal.fire({ html: response.message, icon: "success" })
                setSubmitting(false);
                setStep1(false);
                setStep2(true);
            }).catch(error => {
                console.log(error)
                Swal.fire({html: 'Ha habido un error, intenta más tarde...', icon: "error"})
            })

        });
    }

    const sendEvaluacion2 = async (values, {setSubmitting, resetForm}) => {
        console.log('entra', evaluacionNpsID);
        console.log(values);
        if (auth) {
            authUser = JSON.parse(auth);
            const {user: authUsr} = authUser;
            user = authUsr;
        }
        const bodies = [
            {
                evaluacionNpsID,
                tipo: initialValues2.tipo,
                dia: values.dia,
                datosRegistrados: values.datosRegistrados
            },
            {
                evaluacionNpsID,
                tipo: initialValues2.tipo2,
                dia: values.dia,
                datosRegistrados: values.datosRegistrados2
            }
        ];

        bodies.forEach((body, index) => {
            Services.AddEvaluationDetail(body).then((response) => {
                console.log(response);
                if (index+1 === bodies.length) {
                    setSubmitting(false);
                    resetForm();
                    Swal.fire({html: `${response.message}, te redirigiremos al dashboard`, icon: "success"});
                    navigate('../')
                }
            }).catch(error => {
                console.log(error)
                Swal.fire({html: 'Ha habido un error, intenta más tarde...', icon: "error"})
            })
        })
    }

    const handleOnSubmit = async (values, {setSubmitting}) => {
        // console.log(values)
        const {files} = values;
        for(let i = 0; i < files.length ;i++){
            const body = new FormData();
            body.append('evaluacionNpsID', evaluacionNpsID);
            body.append('testigo', files[i]);

            Services.AddEvaluationPhoto(body, body._boundary).then((response) => {
                // console.log(response);
                if (i+1 === files.length) {
                    Swal.fire({ html: response.message, icon: "success" });
                    setSubmitting(false);
                    setStep2(false);
                    setStep3(true);
                }
            }).catch(error => {
                console.log(error)
                Swal.fire({html: 'Ha habido un error, intenta más tarde...', icon: "error"})
            })
        }
    }

    return (
        <Layout>
            <Navbar/>
            <div className="dashInfo">
                <div className="titleGeneral h-1/4">
                    <div className="font-bold text-white text-center pt-8 pb-12">
                        Agregar Evaluación { report !== null && (
                            <>
                                <br/>
                                del reporte con ID { report.evaluacionNpsID }
                            </>
                    ) }
                    </div>
                </div>

                <div className="bg-white pt-6 px-6 overflow-x-auto h-3/4 rounded-t-3xl -mt-8">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        { step1 && (
                            <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                validate={values => {
                                    const errors = {}

                                    if (!values.dosisHA) {
                                        errors.dosisHA = "La Dosis es requerida"
                                    }
                                    if (!values.metodoAplicacion) {
                                        errors.metodoAplicacion =
                                            "El Metodo de aplicación es requerido"
                                    }
                                    if (!values.frecuencia) {
                                        errors.frecuencia = "La Frecuencia es requerido"
                                    }
                                    if (!values.objetivo) {
                                        errors.objetivo = "El Objetivo es requerido"
                                    }
                                    return errors
                                }}
                                onSubmit={sendEvaluacion}
                            >
                                {({isSubmitting}) => (
                                    <Form>
                                        <div className="mb-2">
                                            <label
                                                className="block text-green-gu text-sm font-medium mb-2"
                                                htmlFor="productorID"
                                            >
                                                Nombre del Productor
                                            </label>
                                            <Field
                                                as="select"
                                                name="productorID"
                                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            >
                                                <option value="">Selecciona un productor</option>
                                                {
                                                    productores.map((productor) => (
                                                        <option value={productor.id} key={productor.id} selected={productorID === productor.id}>{productor.name}</option>
                                                    ))
                                                }

                                            </Field>
                                        </div>
                                        <div className="mb-2">
                                            <label
                                                className="block text-green-gu text-sm font-medium mb-2"
                                                htmlFor="producto"
                                            >
                                                Nombre del Producto a Aplicar
                                            </label>
                                            <Field
                                                as="select"
                                                name="producto"
                                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            >
                                                <option value="">Selecciona una opción</option>
                                                <option value="PEPTONOMRI">Pepton OMRI</option>
                                                <option value="PEPTON8516">PEPTON 85/16</option>
                                                <option value="PREK">PREK PROK</option>
                                                <option value="PLENOX">PLENOX LEO</option>
                                            </Field>
                                        </div>
                                        <div className="mb-2">
                                            <label
                                                className="block text-green-gu text-sm font-medium mb-2"
                                                htmlFor="dosisHA"
                                            >
                                                Dosis/HA
                                            </label>
                                            <Field
                                                type="text"
                                                id="dosisHA"
                                                name="dosisHA"
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                            <ErrorMessage
                                                name="dosisHA"
                                                component="div"
                                                className="text-xs text-red-500"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label
                                                className="block text-green-gu text-sm font-medium mb-2"
                                                htmlFor="metodoAplicacion"
                                            >
                                                Método de Aplicación
                                            </label>
                                            <Field
                                                as="select"
                                                name="metodoAplicacion"
                                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            >
                                                <option value="">Selecciona una opción</option>
                                                <option value="Foliar">Foliar</option>
                                                <option value="Fertirriego">Fertirriego</option>
                                                <option value="Drench">Drench</option>
                                            </Field>
                                        </div>
                                        <div className="mb-2">
                                            <label
                                                className="block text-green-gu text-sm font-medium mb-2"
                                                htmlFor="frecuencia"
                                            >
                                                Frecuencia de Aplicación
                                            </label>
                                            <Field
                                                as="select"
                                                name="frecuencia"
                                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            >
                                                <option value="">Selecciona una opción</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </Field>
                                        </div>
                                        <div className="mb-2">
                                            <label
                                                className="block text-green-gu text-sm font-medium mb-2"
                                                htmlFor="duracion"
                                            >
                                                Duración del Desarrollo en Semanas
                                            </label>
                                            <Field
                                                as="select"
                                                name="duracion"
                                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            >
                                                <option value="">Selecciona una opción</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </Field>
                                        </div>
                                        <div className="mb-2">
                                            <label
                                                className="block text-green-gu text-sm font-medium mb-2"
                                                htmlFor="cultivo"
                                            >
                                                Cultivo
                                            </label>
                                            <Field
                                                as="select"
                                                name="cultivo"
                                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            >
                                                <option value="">Selecciona una opción</option>
                                                <option value="Fresa">Fresa</option>
                                                <option value="Zarzamora">Zarzamora</option>
                                                <option value="Arandano">Arandano</option>
                                                <option value="Frambuesa">Frambuesa</option>
                                            </Field>
                                        </div>
                                        <div className="mb-2">
                                            <label
                                                className="block text-green-gu text-sm font-medium mb-2"
                                                htmlFor="variedad"
                                            >
                                                Variedad
                                            </label>
                                            <Field
                                                type="text"
                                                id="variedad"
                                                name="variedad"
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                            <ErrorMessage
                                                name="variedad"
                                                component="div"
                                                className="text-xs text-red-500"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label
                                                className="block text-green-gu text-sm font-medium mb-2"
                                                htmlFor="objetivo"
                                            >
                                                Objetivo
                                            </label>
                                            <Field
                                                as="textarea"
                                                name="objetivo"
                                                id="objetivo"
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            />
                                        </div>

                                        <br />
                                        <button
                                            type="submit"
                                            className="inline-flex items-center justify-center p-3 w-full bg-green-light-gu rounded-full font-bold text-white focus:ring-2 focus:ring-green-light-gu focus:bg-green-gu"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting && (
                                                <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        stroke-width="4"
                                                        />
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    />
                                                </svg>
                                            )}{" "}
                                            {!isSubmitting ? "Guardar Evaluación" : "Enviando"}
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        ) }

                        { step2 && (
                            <Formik initialValues={{files: null}} onSubmit={handleOnSubmit}>
                                {({values, handleSubmit, isSubmitting, setFieldValue}) => (
                                    <form onSubmit={handleSubmit}>
                                        <label
                                            className="block text-green-gu text-sm font-medium mb-2"
                                            htmlFor="files"
                                        >
                                            Agrega las fotos de los testigos a adjuntar
                                        </label>
                                        <input
                                            id="files"
                                            name="files"
                                            type="file"
                                            multiple
                                            onChange={(event) => {
                                                setFieldValue("files", event.currentTarget.files);
                                            }}
                                        />
                                        <button
                                            type="submit"
                                            className="inline-flex items-center justify-center p-3 w-full bg-green-light-gu rounded-full font-bold text-white focus:ring-2 focus:ring-green-light-gu focus:bg-green-gu"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting && (
                                                <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        stroke-width="4"
                                                    />
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    />
                                                </svg>
                                            )}{" "}
                                            {!isSubmitting ? "Guardar Testigos" : "Enviando"}
                                        </button>
                                    </form>
                                )}
                            </Formik>
                        ) }

                        {
                            step3 && (
                                <Formik
                                    initialValues={cultivo === 'Fresa' ? initialValues2 : initialValues3}
                                    validate={values => {
                                        const errors = {}

                                        if (!values.dia) {
                                            errors.dia = "El dia es requerido"
                                        }

                                        return errors
                                    }}
                                    onSubmit={sendEvaluacion2}
                                >
                                    {({isSubmitting, handleSubmit}) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-2">
                                                <label
                                                    className="block text-green-gu text-sm font-medium mb-2"
                                                    htmlFor="dia"
                                                >
                                                    Día
                                                </label>
                                                <Field
                                                    type="text"
                                                    id="dia"
                                                    name="dia"
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                />
                                                <ErrorMessage
                                                    name="dia"
                                                    component="div"
                                                    className="text-xs text-red-500"
                                                />
                                                <br />
                                                <div className="relative mt-1 mb-2 rounded-md shadow-md w-full ">
                                                    <Field
                                                        name="tipo"
                                                        type="text"
                                                        className="block w-full text-center green-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                        value="Testigo"
                                                    />
                                                </div>
                                                { cultivo === 'Fresa' && (
                                                    <table className="w-full">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>No. Hojas</th>
                                                            <th>No. Coronas</th>
                                                            <th>No. Frutos</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {initialValues2.datosRegistrados.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>T{index + 1}</td>
                                                            <td>
                                                                <div className="relative mt-1 rounded-md shadow-md w-full">
                                                                    <Field
                                                                        name={`datosRegistrados.${index}.hojas`}
                                                                        type="text"
                                                                        className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="relative mt-1 rounded-md shadow-md w-full">
                                                                    <Field
                                                                        name={`datosRegistrados.${index}.coronas`}
                                                                        type="text"
                                                                        className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="relative mt-1 rounded-md shadow-md w-full">
                                                                    <Field
                                                                        name={`datosRegistrados.${index}.frutos`}
                                                                        type="number"
                                                                        className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                    </tbody>
                                                </table>
                                                )}
                                                { cultivo !== 'Fresa' && (
                                                    <div>
                                                        <table className="w-full">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>B. Basales</th>
                                                                    <th>B. Ciegos</th>
                                                                    <th>B. Abiertos</th>
                                                                    <th>Frutos</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {initialValues3.datosRegistrados.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>T{index + 1}</td>
                                                            <td>
                                                                <div className="relative mt-1 rounded-md shadow-md w-full">
                                                                    <Field
                                                                        name={`datosRegistrados.${index}.bBasales`}
                                                                        type="text"
                                                                        className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="relative mt-1 rounded-md shadow-md w-full">
                                                                    <Field
                                                                        name={`datosRegistrados.${index}.bCiegos`}
                                                                        type="text"
                                                                        className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="relative mt-1 rounded-md shadow-md w-full">
                                                                    <Field
                                                                        name={`datosRegistrados.${index}.bAbiertos`}
                                                                        type="number"
                                                                        className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="relative mt-1 rounded-md shadow-md w-full">
                                                                    <Field
                                                                        name={`datosRegistrados.${index}.frutos`}
                                                                        type="number"
                                                                        className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div> 
                                                )}
                                            </div>


                                            <br />
                                            <br />
                                            <div className="mb-2">
                                                <div className="relative mt-1 rounded-md shadow-md w-full ">
                                                    <Field
                                                        name="tipo2"
                                                        type="text"
                                                        className="block w-full text-center green-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                        value="Tratamiento"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                            { cultivo === 'Fresa' && (
                                                <table className="w-full">
                                                <tr>
                                                    <th></th>
                                                    <th>No. Hojas</th>
                                                    <th>No. Coronas</th>
                                                    <th>No. Frutos</th>
                                                </tr>
                                                {initialValues2.datosRegistrados2.map((row2, index2) => {
                                                    return (
                                                        <tr key={index2}>
                                                            <td>P{index2 + 1}</td>
                                                            <td>
                                                                <div className="relative mt-1 rounded-md shadow-md w-full">
                                                                    <Field
                                                                        name={`datosRegistrados2.${index2}.hojas`}
                                                                        type="text"
                                                                        className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="relative mt-1 rounded-md shadow-md w-full">
                                                                    <Field
                                                                        name={`datosRegistrados2.${index2}.coronas`}
                                                                        type="text"
                                                                        className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="relative mt-1 rounded-md shadow-md w-full">
                                                                    <Field
                                                                        name={`datosRegistrados2.${index2}.frutos`}
                                                                        type="number"
                                                                        className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                            )}
                                            { cultivo !== 'Fresa' && (
                                                <div>
                                                <table className="w-full">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>B. Basales</th>
                                                            <th>B. Ciegos</th>
                                                            <th>B. Abiertos</th>
                                                            <th>Frutos</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {initialValues3.datosRegistrados2.map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>P{index + 1}</td>
                                                    <td>
                                                        <div className="relative mt-1 rounded-md shadow-md w-full">
                                                            <Field
                                                                name={`datosRegistrados2.${index}.bBasales`}
                                                                type="text"
                                                                className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="relative mt-1 rounded-md shadow-md w-full">
                                                            <Field
                                                                name={`datosRegistrados2.${index}.bCiegos`}
                                                                type="text"
                                                                className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="relative mt-1 rounded-md shadow-md w-full">
                                                            <Field
                                                                name={`datosRegistrados2.${index}.bAbiertos`}
                                                                type="number"
                                                                className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="relative mt-1 rounded-md shadow-md w-full">
                                                            <Field
                                                                name={`datosRegistrados2.${index}.frutos`}
                                                                type="number"
                                                                className=" w-full text-center gray-bg border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                                    </tbody>
                                                </table>
                                            </div> 
                                            ) }
                                            </div>
                                            

                                            <br />
                                            <button
                                                type="submit"
                                                className="inline-flex items-center justify-center p-3 w-full bg-green-light-gu rounded-full font-bold text-white focus:ring-2 focus:ring-green-light-gu focus:bg-green-gu"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting && (
                                                    <svg
                                                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            stroke-width="4"
                                                        />
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        />
                                                    </svg>
                                                )}{" "}
                                                {!isSubmitting ? "Guardar Evaluación" : "Enviando"}
                                            </button>
                                        </form>
                                    )}
                                </Formik>
                            )
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const Head = () => <Seo title="Agregar Evaluación"/>

export default AddEvaluationPage
